<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <properties-component-image title="World Map|Carte géographique" :value="block.image" no-image-picker v-bind="componentProps">
            <template #before>
                <b-form-group :label="'Address|Adresse'|tr">
                    <b-input-group>
                        <!--                    <b-input-group-text><i class="fas fa-location-dot fa-fw"/></b-input-group-text>-->
                        <e-input type="text" autofocus v-model="block.location" @change="updateUrl(true)" required/>
                    </b-input-group>
                </b-form-group>

                <b-form-group>
                    <btn-properties v-for="mapType in MAP_TYPES" :key="'map-' + mapType.value" @click="block.mapType = mapType.value" :selected="block.mapType === mapType.value"
                                    class="btn-map-type p-0" style="width: 55px; height: 55px;">
                        <b-img fluid :src="require(`@/assets/img/maps/styles/preview-${mapType.value.toLowerCase()}.png`)"/>
                    </btn-properties>
                </b-form-group>

                <b-form-group :label="'Zoom Level|Niveau de zoom'|tr">
                    <b-input type="range" v-model="block.zoom" :min="2" :max="20" @change="updateUrl(true)"/>
                </b-form-group>

                <b-form-group :label="'Size|Taille'|tr">
                    <b-input-group>
                        <e-input type="number" v-model="block.width" :min="100" :max="2000" required state="disabled"/>
                        <b-input-group-text>x</b-input-group-text>
                        <e-input type="number" v-model="block.height" :min="100" :max="2000" required state="disabled"/>
                        <b-input-group-text>px</b-input-group-text>
                    </b-input-group>
                </b-form-group>

                <b-form-group>
                    <b-checkbox v-model="block.marker">
                        <span v-tr>Show Location Market|Afficher le marqueur d'emplacement</span>
                    </b-checkbox>
                </b-form-group>

                <b-form-group>
                    <b-checkbox v-model="block.legend">
                        <span v-tr>Show Distance Legend|Afficher la légende des distances</span>
                    </b-checkbox>
                </b-form-group>

                <b-form-group>
                    <b-checkbox v-model="block.traffic">
                        <span v-tr>Show Traffic|Afficher le traffic</span>
                    </b-checkbox>
                </b-form-group>

                <template v-if="block.traffic">
                    <b-form-group>
                        <b-checkbox v-model="block.incidents">
                            <span v-tr>Show Incidents|Afficher les incidents</span>
                        </b-checkbox>
                    </b-form-group>

                    <b-form-group>
                        <b-checkbox v-model="block.construction_sites">
                            <span v-tr>Show Constructions Sites|Afficher les travaux</span>
                        </b-checkbox>
                    </b-form-group>
                </template>

                <b-btn variant="info" @click="block.loadMap()">
                    <span v-tr>Reload Map|Recharger le plan</span>
                </b-btn>
            </template>
        </properties-component-image>
        <properties-component-text title="Legend|Légende" :value="block.legend">
            <b-form-group label-cols="4" :label="'Position|Position'|tr">
                <b-select v-model="block.legendPosition">
                    <option value="top" v-tr>Top|En haut</option>
                    <option value="bottom" v-tr>Bottom|En bas</option>
                    <option value="left" v-tr>Left|Gauche</option>
                    <option value="right" v-tr>Right|Droite</option>
                </b-select>
            </b-form-group>
            <b-form-group label-cols="4" :label="'Theme|Thème'|tr">
                <b-select v-model="block.legendTheme">
                    <option value="transparent" v-tr>Transparent|Transparent</option>
                    <option value="light" v-tr>Light|Clair</option>
                    <option value="dark" v-tr>Dark|Foncé</option>
                </b-select>
            </b-form-group>
            <b-form-group label-cols="4" :label="'Spacing|Espacement'|tr">
                <b-select v-model="block.legendPadding">
                    <option value="0" v-tr>None|Sans</option>
                    <option v-for="n in 50" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
                </b-select>
            </b-form-group>
        </properties-component-text>
    </properties-block>
</template>

<script>
import PropertiesComponentImage from "@/components/editor/properties/components/properties-component-image.vue";
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";
import PropertiesComponentText from "@/components/editor/properties/components/properties-component-text.vue";
import BtnProperties from "@/components/editor/properties/inputs/btn-properties.vue";
import EInput from "../../../../../vue-components/components/e-input.vue";

// properties-block-world-map #hot-reload-debug
export default {
    name: `properties-block-world-map`,
    components: {BtnProperties, EInput, PropertiesComponentText, PropertiesComponentImage, PropertiesBlock},
    extends: PropertiesBlock,
    data() {
        return {
            MAP_TYPES: {
                ROADMAP: {value: `ROADMAP`, name: `Road Map|`},
                SATELLITE: {value: `SATELLITE`, name: `Satellite|Satellite`},
                HYBRID: {value: `HYBRID`, name: `Hybrid|`},
                LIGHT: {value: `LIGHT`, name: `Light|Claire`},
                DARK: {value: `DARK`, name: `Dark|`}
            }
        }
    },
    methods: {
        updateUrl() {
            this.block.loadMap();
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
