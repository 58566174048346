<template>
    <div class="btn-properties" v-on="$listeners" :class="[{ 'selected': selected}]">
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: `btn-properties`,
    props: {
        selected: {type: Boolean}
    }
}
</script>

<style lang="scss" scoped>
.btn-properties {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 70px;
    margin-right: 3px;
    margin-bottom: 3px;
    background-color: white;
    border-radius: var(--border-radius);
    border: 2px solid rgba(black, 0.2);
    cursor: pointer;
    overflow: hidden;
    vertical-align: top;

    &:hover {
        border-color: var(--accent-color);
    }

    &.selected {
        transform: scale(0.95);
        border-color: var(--accent-color);
    }
}
</style>
